/**
* Change style sheet
* Functionality avalible in front-end to change between themes
*/

.style-burger {
  z-index: 300;
	position: fixed;
  right: 15px;
  bottom: 100px;
	display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: var(--color-primary);
  border: 1px solid #fff;
  transition: all .2s ease-in-out;

  &.active {
    .style-burger-icon {
      span:nth-child(1) {
        transform: rotate(45deg);
        top: -3px;
        left: 4px;
      }
  
      span:nth-child(2) {
        width: 0;
        opacity: 0;
      }
  
      span:nth-child(3) {
        transform: rotate(-45deg);
        top: 18px;
        left: 4px;
      }
    }
  }

  p {
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    text-shadow: #e0e0e0 1px 1px 0, 2px 2px 2px rgba(0,0,0,0);
    margin-bottom: 0;
    margin-right: 10px;
  }

  .style-burger-icon {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 18px;
    background-image: none;
    transition: all .2s ease-in-out;

    span {
      position: absolute;
      left: 0;
      height: 2px;
      width: 100%;
      background-color: var(--color-primary-text);
      opacity: 1;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
    }

    span:nth-child(1) {
      top: 0;
      transform-origin: left center;
    }

    span:nth-child(2) {
      top: 7.5px;
      transform-origin: left center
    }

    span:nth-child(3) {
      top: 16px;
      transform-origin: left center;
    }
  }
}

.style-menu {
  z-index: 200;
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: min-content;
  background-color: var(--color-primary);
  box-shadow: 0 2px 4px 4px rgba(0, 0, 0, 0.05);
  transform: translateX(100%);
  transition: all 0.2s ease-in-out;

  &.show {
    transform: translateX(0);
  }

  h2 {
    color: var(--color-primary-text);
    padding: 20px 15px 10px 15px;
    margin-bottom: 0;
  }

  .form-group,
  .dropdown {
    width: auto;
    margin: 0;

    @media (max-width: 991.98px) {
      margin: 0;
    }
  }

  .form-group {
    label {
      font-weight: 700;
    }

    input {
      width: 100%;
      height: 28px;
      margin-top: 5px;
    }

    p {
      font-size: 0.875rem; // 14px
      margin-bottom: 0;
    }
  }

  .dropdown {
    background: #fff;
    margin: 1px;

    &.show {
      .dropdown-toggle {
        opacity: .5;

        &:after {
          top: 55%;
          transform: translate(0, -50%) rotate(-45deg);
        }
      }
    }
  }

  .dropdown-toggle {
    height: auto;
    width: 225px;
    border-radius: 0;
    padding: 10px 30px 10px 15px;
    text-align: left;
    font-weight: 600;
    background-color: var(--color-primary);
    color: var(--color-primary-text);
    opacity: .9;
    font-size: 0.875rem; // 14px
    white-space: initial;

    &:after {
      position: absolute;
      content: "";
      display: flex;
      right: 15px;
      top: 45%;
      transition: all 0.2s ease-in-out;
      width: 9px;
      height: 9px;
      border-style: solid;
      border-color: var(--color-primary-text);
      border-width: 2px 2px 0 0;
      transform: translate(0, -50%) rotate(135deg);
    }
  }

  .dropdown-menu {
    max-height: 50vh;
    width: 100% !important;
    list-style: none;
    border-radius: 0;
    border: 2px solid var(--color-primary);
    padding: 0;
    overflow: auto;
    top: -107% !important;
    left: -98% !important;

    &.list-padding {
      li {
        padding: 10px 15px;
      }
    }

    li {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      border-bottom: 1px solid #000;
      font-size: 0.875rem; // 14px
      
      &:last-of-type {
        margin-bottom: 0;
        border-bottom: none;
      }

      button {
        padding: 10px 15px;
        text-align: left;
        transition: all 0.2s ease-in-out;
        font-weight: 700;
        font-size: 0.875rem; // 14px

        &:hover,
        &:focus,
        &.active {
          background-color: var(--color-primary);
          color: var(--color-primary-text);
        }
      }

      .btn-group {
        .btn {
          display: flex;
          justify-content: center;
          font-size: 0.75rem; // 12px
          min-width: auto;
          padding: 2px 5px;
          margin-right: 10px;
          margin-top: 5px;
        }
      }
    }
  }
}

body.dark-theme {
  .style-menu  {
    background-color: var(--darkmode-800);

    .dropdown-toggle {
      background-color: var(--darkmode-700);
    }

    .dropdown-menu {
      border: 2px solid var(--darkmode-800);

      &::-webkit-scrollbar {
        background-color: var(--darkmode-600);
      }
  
      &::-webkit-scrollbar-thumb {
        background-color: var(--darkmode-hightlight-1-200);
      }

      li {
        background-color: var(--darkmode-700);
        border-bottom: 1px solid var(--darkmode-800);
  
        button {
          color: var(--darkmode-50);
          background-color: var(--darkmode-700);
        }

        .btn-group {
          button {
            color: var(--darkmode-900);
            background-color: var(--darkmode-hightlight-1-200);
          }
        }

        .form-group input {
          color: var(--darkmode-50);
          background-color: var(--darkmode-600);
        }
      }
    }
  }

  .style-burger {
    border: 1px solid var(--darkmode-700);
    background-color: var(--darkmode-800);

    p {
      text-shadow: var(--darkmode-900) 1px 1px 0, 2px 2px 2px rgb(0 0 0 / 0%);
    }

    .style-burger-icon span {
      background-color: var(--darkmode-hightlight-1-200);
    }
  }
}
